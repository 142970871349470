var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-box"},[_c('header-bar',{attrs:{"swiperActiveIndex":1,"white":""}}),_vm._m(0),_c('div',{staticClass:"yewu-box"},[_c('div',{staticClass:"page-content",style:({
        height:0.5*_vm.innerWidth+'px'
      })},[_vm._m(1),_c('div',{staticClass:"img img4"})]),_c('div',{staticClass:"page-content",style:({
        height:0.5*_vm.innerWidth+'px'
      })},[_c('div',{staticClass:"img img1"}),_vm._m(2)]),_c('div',{staticClass:"page-content",style:({
        height:0.5*_vm.innerWidth+'px'
      })},[_vm._m(3),_c('div',{staticClass:"img img2"})]),_c('div',{staticClass:"page-content",style:({
        height:0.5*_vm.innerWidth+'px'
      })},[_c('div',{staticClass:"img img3"}),_vm._m(4)])]),_c('footer-bar')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',[_c('div',{staticClass:"en"},[_vm._v("ZHANG"),_c('span',[_vm._v("WAN")])]),_c('div',{staticClass:"block"},[_c('span'),_c('span'),_c('span')]),_c('div',{staticClass:"title2"},[_vm._v("掌玩业务-新媒体")]),_c('div',{staticClass:"content2"},[_vm._v("互联网广告投放业务 互联网流量代理业务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("短剧项目")]),_c('p',{staticClass:"text"},[_vm._v(" 掌玩于2022年末开始布局短剧业务，与抖音、腾讯、快手、百度等平台深度合作，凭借掌玩强大的广告投放能力，目前月流水稳定在亿级以上，成功在市场上推广多部优秀短剧，随着短视频内容多元化的发展，掌玩在业务模式上不断探索，开始创作自己的短剧集，致力于搭建国内顶尖的短剧平台。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("数字阅读")]),_c('p',{staticClass:"text"},[_vm._v(" 业务现在增涨迅猛，是包括番茄小说、阅文集团、七猫小说在内的各大小说平台核心代理，分发商。每年在抖音、腾讯、快手以及Facebook等平台广告消耗达到亿级，是行业内的头部企业，成为行业风向标，引领行业发展。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("游戏发行")]),_c('p',{staticClass:"text"},[_vm._v(" 游戏业务于2021年立项，在三年时间内迅猛发展，成为掌玩的一大核心稳定业务。运营产品涵盖了角色扮演、卡牌策略、休闲竞技等类型，拥有传奇、三国、二次元、仙侠等垂直领域大“IP”资源。凭借强大的技术实力和专业的运营团队，发行了多款业内知名精品大作，并配合产品研发公司进行了多次产品调优，获得了业内和玩家的一致认可。 ")]),_c('p',{staticClass:"text"},[_vm._v(" 目前掌玩已成为全国知名游戏发行公司，打造出多款业内瞩目的精品游戏，产品月流水稳定至亿级以上。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("新媒体广告")]),_c('p',{staticClass:"text"},[_vm._v(" 「新媒体广告」是掌玩的起家业务，自有公众号涉及情感，汽车，旅游，生活等众多垂直领域，曾打造《一星期读一本书》《豪哥说车》《糗事大百科》等知名大号，截止目前公众号粉丝累计过亿，依然是行业头部企业，在行业内拥有极大的影响力。 ")])])])
}]

export { render, staticRenderFns }