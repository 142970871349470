<template>
  <div class="page-box">
    <header-bar
      :swiperActiveIndex="1"
      white
    />
    <div class="banner">
      <div>
        <div class="en">ZHANG<span>WAN</span></div>
        <div class="block">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="title2">掌玩业务-新媒体</div>
        <div class="content2">互联网广告投放业务 互联网流量代理业务</div>
      </div>
    </div>
    <div class="yewu-box">
      <div
        class="page-content"
        :style="{
          height:0.5*innerWidth+'px'
        }"
      >
        <div class="content">
          <div>
            <p class="title">短剧项目</p>
            <p class="text">
              掌玩于2022年末开始布局短剧业务，与抖音、腾讯、快手、百度等平台深度合作，凭借掌玩强大的广告投放能力，目前月流水稳定在亿级以上，成功在市场上推广多部优秀短剧，随着短视频内容多元化的发展，掌玩在业务模式上不断探索，开始创作自己的短剧集，致力于搭建国内顶尖的短剧平台。
            </p>
          </div>
        </div>
        <div class="img img4"></div>
      </div>
      <div
        class="page-content"
        :style="{
          height:0.5*innerWidth+'px'
        }"
      >
        <div class="img img1"></div>
        <div class="content">
          <div>
            <p class="title">数字阅读</p>
            <p class="text">
              业务现在增涨迅猛，是包括番茄小说、阅文集团、七猫小说在内的各大小说平台核心代理，分发商。每年在抖音、腾讯、快手以及Facebook等平台广告消耗达到亿级，是行业内的头部企业，成为行业风向标，引领行业发展。
            </p>
          </div>
        </div>
      </div>
      <div
        class="page-content"
        :style="{
          height:0.5*innerWidth+'px'
        }"
      >
        <div class="content">
          <div>
            <p class="title">游戏发行</p>
            <p class="text">
              游戏业务于2021年立项，在三年时间内迅猛发展，成为掌玩的一大核心稳定业务。运营产品涵盖了角色扮演、卡牌策略、休闲竞技等类型，拥有传奇、三国、二次元、仙侠等垂直领域大“IP”资源。凭借强大的技术实力和专业的运营团队，发行了多款业内知名精品大作，并配合产品研发公司进行了多次产品调优，获得了业内和玩家的一致认可。
            </p>
            <p class="text">
              目前掌玩已成为全国知名游戏发行公司，打造出多款业内瞩目的精品游戏，产品月流水稳定至亿级以上。
            </p>
          </div>
        </div>
        <div class="img img2"></div>
      </div>
      <div
        class="page-content"
        :style="{
          height:0.5*innerWidth+'px'
        }"
      >
        <div class="img img3"></div>
        <div class="content">
          <div>
            <p class="title">新媒体广告</p>
            <p class="text">
              「新媒体广告」是掌玩的起家业务，自有公众号涉及情感，汽车，旅游，生活等众多垂直领域，曾打造《一星期读一本书》《豪哥说车》《糗事大百科》等知名大号，截止目前公众号粉丝累计过亿，依然是行业头部企业，在行业内拥有极大的影响力。
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer-bar />
  </div>
</template>
<script>
export default {
  data() {
    return {
      scale: 1,
      innerWidth: 1,
      flexWidth: 1
    }
  },
  mounted() {
    this.setScale()
    window.addEventListener('resize', this.setScale)
  },
  methods: {
    setScale() {
      this.scale = window.innerWidth / 1920
      this.innerWidth = window.innerWidth / 2
    }
  },
  destroyed() {
    //
    window.removeEventListener('resize', this.setScale)
  }
}
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  height: 100%;
  background: url(/bg/3.jpg);
  background-position: center center;
}
.yewu-box {
  // max-width: 1360px;
  // margin: 2% auto;
  padding: 4% 0;
}
.page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3% 10%;
  .img,
  .content {
    width: 50%;
    align-self: stretch;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  img {
    width: 100%;
    display: block;
  }
  .content {
    box-sizing: border-box;
    padding: 50px;
    display: flex;
    align-items: center;
    .title,
    .text {
      max-width: 600px;
    }
    .title {
      font-size: 30px;
    }
    .text {
      margin-top: 5%;
      line-height: 34px;
    }
  }
}
.img1 {
  background-image: url('/bg/new/slide1.jpg');
}
.img2 {
  background-image: url('/bg/new/slide3.jpg');
}
.img3 {
  background-image: url('/bg/new/slide2.jpg');
}
.img4 {
  background-image: url('/bg/new/slide4.png');
}
</style>